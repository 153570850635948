import type { ModalSettings, ModalStore, ToastStore } from '@skeletonlabs/skeleton'
import camelcaseKeys from 'camelcase-keys'
import type { FullAutoFill } from 'svelte/elements'
import { get, writable } from 'svelte/store'

import { FetchError, handleFetch } from '@utils/fetch'

enum StorageType {
  AWS_S3 = 'AWS_S3',
  S3_COMPATIBLE = 'S3_COMPATIBLE',
  GCP = 'GCP',
  Git = 'Git',
  Local = 'Local',
}

const getStorageType = (storageConfig: IRawStorageConfig) => {
  if (storageConfig.s3) {
    return storageConfig.s3.endpointUrl ? StorageType.S3_COMPATIBLE : StorageType.AWS_S3
  }
  if (storageConfig.gcp) {
    return StorageType.GCP
  }
  if (storageConfig.git) {
    return StorageType.Git
  }
  return storageConfig.type
}

const storageTypesValues = Object.values(StorageType)

const displayStorageTypes = {
  [StorageType.AWS_S3]: 'AWS S3',
  [StorageType.S3_COMPATIBLE]: 'S3 Compatible',
  [StorageType.GCP]: 'Google Cloud Storage',
  [StorageType.Git]: 'Git',
  [StorageType.Local]: 'Local',
}

interface IStorageConfig {
  id: number
  name: string
  type: StorageType
  createdAt: Date
  updatedAt: Date
  organizationId?: number
  s3?: IStorageS3
  gcp?: IStorageGCS
  git?: IStorageGit
  creatorId: number
  creatorName: string
}

type IRawStorageConfig = Omit<IStorageConfig, 'createdAt' | 'updatedAt'> & {
  createdAt: string
  updatedAt: string
}

interface IStorageS3 {
  endpointUrl?: string
  bucketUrl: string
  regionName: string
  accessKeyId: string
  secretAccessKey: string
}

interface IStorageGCS {
  bucketName: string
  project: string
  credentialsJson: string
}

interface IGitRepo {
  id?: number
  repositoryUrl: string
  plainAuth?: {
    username: string
    password: string
  }
  sshAuth?: {
    sshKey: string
    sshPassword: string
  }
}

interface IStorageGit {
  repo: IGitRepo
  branch: string
}

function isS3(storageConfig: unknown, type?: StorageType | null): storageConfig is IStorageS3 {
  return StorageType.S3_COMPATIBLE == type || StorageType.AWS_S3 == type
}

function isS3Field(
  storageConfig: unknown,
  type: StorageType | null,
  field: string,
): field is keyof IStorageS3 {
  return isS3(storageConfig, type) && storageConfig && field in storageConfig
}

function isGCS(storageConfig: unknown, type?: StorageType | null): storageConfig is IStorageGCS {
  return StorageType.GCP == type
}

function isGCSField(
  storageConfig: unknown,
  type: StorageType | null,
  field: string,
): field is keyof IStorageGCS {
  return isGCS(storageConfig, type) && storageConfig && field in storageConfig
}

function isGit(storageConfig: unknown, type?: StorageType | null): storageConfig is IStorageGit {
  return StorageType.Git == type
}

function isGitField(
  storageConfig: unknown,
  type: StorageType | null,
  field: string,
): field is keyof IStorageGit {
  return isGit(storageConfig, type)
}

function isValidName(name: unknown): name is string {
  return typeof name === 'string' && name.trim() !== ''
}

function isValidType(type: unknown): type is StorageType {
  return typeof type === 'string' && storageTypesValues.includes(type as StorageType)
}

function isValidOrganizationId(organizationId: unknown): organizationId is number {
  return typeof organizationId === 'number' && organizationId > 0
}

interface IGetPrefix {
  storageInputValues: Record<string, unknown>
  selectedStorageConfig: IStorageConfig | null
  type?: StorageType | null
}
const getStoragePrefix = ({ storageInputValues, selectedStorageConfig, type }: IGetPrefix) => {
  if (isGCS(selectedStorageConfig?.gcp, type)) {
    if (selectedStorageConfig) {
      return `gs://${selectedStorageConfig.gcp.bucketName}/`
    } else {
      return `gs://${storageInputValues.bucketName || 'unknown'}/`
    }
  } else if (isS3(selectedStorageConfig?.s3, type)) {
    if (selectedStorageConfig) {
      return `${selectedStorageConfig.s3.bucketUrl}/`
    } else {
      return `${!!storageInputValues.bucketUrl && storageInputValues.bucketUrl != 's3://' ? storageInputValues.bucketUrl : 's3://unknown'}/`
    }
  } else if (isGit(selectedStorageConfig?.git, type)) {
    if (selectedStorageConfig) {
      return `${selectedStorageConfig.git.repo.repositoryUrl}/`
    } else {
      return `${!!storageInputValues.repositoryUrl && storageInputValues.repositoryUrl != 'https://' ? storageInputValues.repositoryUrl : 'https://unknown'}/`
    }
  } else if (type == StorageType.Local) {
    return 'file:///'
  } else {
    return 'unknown://'
  }
}

const storageConfigAsyncState = writable({
  loading: false,
  error: '',
})

const storageConfigStore = writable(new Map<number, IStorageConfig>())

storageConfigStore.subscribe((storageConfigs) => {
  console.log('Storage configs updated: ', storageConfigs)
})

const gitRepoStore = writable(new Map<number, IGitRepo>())
const gitRepoAsyncState = writable({
  loading: false,
  error: '',
})

gitRepoStore.subscribe((gitRepos) => {
  console.log('Git repos updated: ', gitRepos)
})

const parseConfigs = (rawConfigs: IRawStorageConfig): IStorageConfig => {
  const {
    id,
    name,
    type,
    createdAt,
    updatedAt,
    organizationId,
    s3,
    gcp,
    git,
    creatorId,
    creatorName,
  } = rawConfigs

  return {
    id,
    name,
    type: getStorageType(rawConfigs) ?? type,
    createdAt: new Date(Date.parse(createdAt)),
    updatedAt: new Date(Date.parse(updatedAt)),
    organizationId,
    s3,
    gcp,
    git,
    creatorId,
    creatorName,
  }
}

interface IGitRepoCreateResponse {
  id: number
}

const convertConfigsForServer = (
  payload: IStorageConfig,
  gitRepoResponse: IGitRepoCreateResponse | null,
): Record<string, unknown> => ({
  ...payload,
  type:
    payload.type == StorageType.S3_COMPATIBLE
      ? 'S3'
      : payload.type == StorageType.AWS_S3
        ? 'S3'
        : payload.type,
  ...(payload.git && gitRepoResponse && gitRepoResponse.id
    ? { git: { repoId: gitRepoResponse.id, branch: payload.git.branch } }
    : payload.git
      ? { git: { repoId: payload.git.repo.id, branch: payload.git.branch } }
      : {}),
})

const loadGitRepos = async () => {
  try {
    const gitRepos = camelcaseKeys(await (await handleFetch('/git-repo/')).json(), { deep: true })
    gitRepoStore.set(
      gitRepos.reduce((acc: Map<number, IGitRepo>, gitRepo: IGitRepo) => {
        if (gitRepo.id) {
          acc.set(gitRepo.id, gitRepo)
        }
        return acc
      }, new Map()),
    )
    gitRepoAsyncState.set({
      loading: false,
      error: '',
    })
  } catch (err) {
    console.error('Failed to load git repos: ', err)
    gitRepoAsyncState.set({
      loading: false,
      error: 'Failed to load git repos',
    })
  }
}

const loadStorageConfig = async () => {
  storageConfigAsyncState.set({
    loading: true,
    error: '',
  })
  type IRawStorageConfig = IStorageConfig & { createdAt: string; updatedAt: string }
  try {
    const storageConfigs: IRawStorageConfig[] = camelcaseKeys(
      await (await handleFetch('/storage-config/')).json(),
      { deep: true },
    )
    if (storageConfigs.some((config) => config.git)) {
      await loadGitRepos()
    }
    const storageConfigMap = storageConfigs.reduce((acc, storageConfig) => {
      acc.set(storageConfig.id, parseConfigs(storageConfig))
      return acc
    }, new Map<number, IStorageConfig>())
    storageConfigStore.set(storageConfigMap)
    storageConfigAsyncState.set({
      loading: false,
      error: '',
    })
  } catch (err) {
    console.error('Failed to load storage configs: ', err)
    storageConfigAsyncState.set({
      loading: false,
      error: 'Failed to load storage configs',
    })
  }
}

interface IBaseField {
  fieldName: string
  label: string
  type: string
  required?: boolean
  showField?: (inputValues: Record<string, unknown>) => boolean
}

interface ITextField extends IBaseField {
  type: 'text' | 'password' | 'url'
  pattern?: RegExp
  autocomplete?: FullAutoFill
}

interface IFileField extends IBaseField {
  type: 'file'
  accept: string
}

interface ISelectField extends IBaseField {
  type: 'select'
  options: Record<string, string>
}

type IField = ITextField | IFileField | ISelectField

const s3SharedFields: ITextField[] = [
  {
    fieldName: 'bucketUrl',
    label: '*Bucket URL',
    type: 'text',
    required: true,
    pattern: /^[a-z0-9.-]{3,64}[/]?$/,
  },
  { fieldName: 'regionName', label: '*Region name', type: 'text', required: true },
  { fieldName: 'accessKeyId', label: '*Access key ID', type: 'text', required: true },
  {
    fieldName: 'secretAccessKey',
    label: '*Secret access key',
    type: 'password',
    required: true,
    autocomplete: 'off',
  },
]

const storageFields: Record<StorageType, IField[]> = {
  [StorageType.AWS_S3]: [...s3SharedFields],
  [StorageType.S3_COMPATIBLE]: [
    { fieldName: 'endpointUrl', label: 'Endpoint URL', type: 'url' },
    ...s3SharedFields,
  ],
  [StorageType.GCP]: [
    {
      fieldName: 'bucketName',
      label: '*Bucket name',
      type: 'text',
      required: true,
      pattern: /^([a-z0-9][a-z0-9_-]{1,61}[a-z0-9](\.[a-z0-9][a-z0-9_-]{1,61}[a-z0-9])*)$/,
    },
    { fieldName: 'project', label: '*Project', type: 'text', required: true },
    {
      fieldName: 'credentialsJson',
      label: '*Credentials JSON',
      type: 'file',
      required: true,
      accept: 'application/json',
    },
  ],
  [StorageType.Git]: [
    { fieldName: 'repo.repositoryUrl', label: '*Repository URL', type: 'url', required: true },
    {
      fieldName: 'repo.authType',
      label: 'Auth type',
      type: 'select',
      required: true,
      options: {
        none: 'None',
        plain: 'Plain',
        ssh: 'SSH',
      },
    },
    {
      fieldName: 'repo.plainAuth.username',
      label: '*Username',
      type: 'text',
      required: true,
      showField: (inputValues) => inputValues['repo.authType'] === 'plain',
    },
    {
      fieldName: 'repo.plainAuth.password',
      label: '*Password',
      type: 'password',
      required: true,
      showField: (inputValues) => inputValues['repo.authType'] === 'plain',
      autocomplete: 'off',
    },
    {
      fieldName: 'repo.sshAuth.sshKey',
      label: '*SSH key',
      type: 'file',
      accept: 'application/x-pem-file',
      required: true,
      showField: (inputValues) => inputValues['repo.authType'] === 'ssh',
    },
    {
      fieldName: 'repo.sshAuth.ssPassword',
      label: '*SSH password',
      type: 'password',
      showField: (inputValues) => inputValues['repo.authType'] === 'ssh',
      autocomplete: 'off',
    },
    { fieldName: 'branch', label: '*Branch', type: 'text', required: true },
  ],
  [StorageType.Local]: [],
}

const getPattern = (field: IField) => {
  if ('pattern' in field) {
    return field.pattern?.source
  }
}

const createStorageConfig = async (
  payload: IStorageConfig,
  toastStore: ToastStore,
  creatorId: number,
  creatorName: string,
) => {
  try {
    const gitRepoResponse = payload.git
      ? await (
          await handleFetch('/git-repo/', {
            method: 'POST',
            body: {
              name: payload.name,
              repositoryUrl: payload.git.repo.repositoryUrl,
              organizationId: payload.organizationId,
              plainAuth: payload.git.repo.plainAuth?.username ? payload.git.repo.plainAuth : null,
              sshAuth: payload.git.repo.sshAuth?.sshKey ? payload.git.repo.sshAuth : null,
            },
          })
        ).json()
      : null
    const response = await handleFetch(`/storage-config/`, {
      method: 'POST',
      body: convertConfigsForServer(payload, gitRepoResponse),
    })

    const rawStorageConfig = camelcaseKeys(await response.json())
    storageConfigStore.update(($storageConfigStore) => {
      if (!isValidName(payload.name)) {
        toastStore.trigger({
          message: 'Storage config is missing a valid name',
          background: 'variant-filled-error',
        })
        throw new Error('Invalid name')
      }

      if (!isValidType(payload.type)) {
        toastStore.trigger({
          message: 'Storage config is missing a valid type',
          background: 'variant-filled-error',
        })
        throw new Error('Invalid type')
      }

      if (!isValidOrganizationId(payload.organizationId)) {
        toastStore.trigger({
          message: 'Storage config is missing a valid organization ID',
          background: 'variant-filled-error',
        })
        throw new Error('Invalid organization ID')
      }

      const { name, type, organizationId } = payload

      let s3: IStorageS3 | undefined
      let gcp: IStorageGCS | undefined
      let git: IStorageGit | undefined

      if (isS3(payload.s3, type)) {
        s3 = payload.s3
      } else if (isGCS(payload.gcp, type)) {
        gcp = payload.gcp
      } else if (isGit(payload.git, type)) {
        git = payload.git
      } else if (type !== StorageType.Local) {
        toastStore.trigger({
          message: 'Storage config type is unrecognized',
          background: 'variant-filled-error',
        })
        throw new Error('Invalid storage config')
      }
      $storageConfigStore.set(
        rawStorageConfig.id,
        parseConfigs({
          id: rawStorageConfig.id,
          name,
          type,
          organizationId,
          s3,
          gcp,
          git,
          createdAt: rawStorageConfig.createdAt,
          updatedAt: rawStorageConfig.updatedAt,
          creatorId,
          creatorName,
        }),
      )
      return $storageConfigStore
    })
    toastStore.trigger({
      message: `Created storage config named <strong>${payload.name}</strong>`,
      background: 'variant-filled-success',
    })

    return rawStorageConfig // return the config if needed for further use
  } catch (err) {
    console.error(err)
    if (err instanceof FetchError && err.message) {
      toastStore.trigger({
        message: `Failed to create storage config: ${err.message}`,
        background: 'variant-filled-error',
        autohide: false,
      })
      throw err
    }
    toastStore.trigger({
      message: 'Failed to create storage config',
      background: 'variant-filled-error',
    })
    throw err
  }
}

const deleteStorageConfig = async (storageConfigId: number) => {
  if (!storageConfigId) {
    console.error('Refusing to delete an undefined storage config ID')
    return
  }

  await handleFetch(`/storage-config/${storageConfigId}`, {
    method: 'DELETE',
  })

  storageConfigStore.update((store) => {
    store.delete(storageConfigId)
    return store
  })
}

const openCreateStorageConfigModal = (
  modalStore: ModalStore,
  toastStore: ToastStore,
  creatorId: number,
  creatorName: string,
) => {
  const modal: ModalSettings = {
    type: 'component',
    component: 'storageFormModal',
    title: 'Create storage config',
    body: 'Please fill in the details to create a new storage config.',
    buttonTextSubmit: 'Create',
    meta: {},
    response: async (response) => {
      if (!response) {
        return
      }
      const { payload, closeModal } = response
      await createStorageConfig(payload, toastStore, creatorId, creatorName)
      closeModal()
    },
  }
  modalStore.trigger(modal)
}

const editStorageConfig = async (
  payload: IStorageConfig,
  storageConfigId: number,
  toastStore: ToastStore,
) => {
  try {
    const gitRepoResponse =
      payload.git?.repo.id && payload.git
        ? await (
            await handleFetch(`/git-repo/${payload.git.repo.id}`, {
              method: 'PUT',
              body: {
                name: payload.name,
                repositoryUrl: payload.git.repo.repositoryUrl,
                organizationId: payload.organizationId,
                plainAuth: payload.git.repo.plainAuth?.username ? payload.git.repo.plainAuth : null,
                sshAuth: payload.git.repo.sshAuth?.sshKey ? payload.git.repo.sshAuth : null,
              },
            })
          ).json()
        : null
    const response = await handleFetch(`/storage-config/${storageConfigId}`, {
      method: 'PUT',
      body: convertConfigsForServer(payload, gitRepoResponse),
    })
    const rawStorageConfig = camelcaseKeys(await response.json())
    storageConfigStore.update(($storageConfigStore) => {
      const existingStorageConfig = $storageConfigStore.get(storageConfigId)
      if (!existingStorageConfig) {
        console.error('Existing storage config not found')
        return $storageConfigStore
      }
      const { id, type, createdAt, creatorId, creatorName } = existingStorageConfig

      if (!isValidName(payload.name)) {
        toastStore.trigger({
          message: 'Storage config is missing a valid name',
          background: 'variant-filled-error',
        })
        return $storageConfigStore
      }

      if (!isValidOrganizationId(payload.organizationId)) {
        toastStore.trigger({
          message: 'Storage config is missing a valid organization ID',
          background: 'variant-filled-error',
        })
        return $storageConfigStore
      }

      const { name, organizationId } = payload

      let s3: IStorageS3 | undefined
      let gcp: IStorageGCS | undefined
      let git: IStorageGit | undefined

      if (isS3(payload.s3, type)) {
        s3 = payload.s3
      } else if (isGCS(payload.gcp, type)) {
        gcp = payload.gcp
      } else if (isGit(payload.git, type)) {
        git = payload.git
      } else if (type !== StorageType.Local) {
        toastStore.trigger({
          message: 'Storage config type is unrecognized',
          background: 'variant-filled-error',
        })
        return $storageConfigStore
      }

      $storageConfigStore.set(
        storageConfigId,
        parseConfigs({
          id,
          name,
          type,
          organizationId,
          s3,
          gcp,
          git,
          createdAt: createdAt.toString(),
          updatedAt: rawStorageConfig.updatedAt,
          creatorId,
          creatorName,
        }),
      )
      return $storageConfigStore
    })
    toastStore.trigger({
      message: `Updated storage config named <strong>${payload.name}</strong>`,
      background: 'variant-filled-success',
    })

    return rawStorageConfig
  } catch (err) {
    console.error('Failed to edit storage config', err)
    if (err instanceof FetchError && err.message) {
      toastStore.trigger({
        message: `Failed to edit storage config: ${err.message}`,
        background: 'variant-filled-error',
        autohide: false,
      })
      return
    }
    toastStore.trigger({
      message: 'Failed to edit storage config',
      background: 'variant-filled-error',
    })
  }
}

const openEditStorageConfigModal =
  (modalStore: ModalStore, toastStore: ToastStore, storageConfigId: number) => () => {
    const storageConfigToEdit = get(storageConfigStore).get(storageConfigId)
    if (!storageConfigToEdit) {
      console.error('Storage config not found')
      return
    }
    if (storageConfigToEdit.git?.repo.id) {
      const repo = get(gitRepoStore).get(storageConfigToEdit.git.repo.id)
      if (repo) {
        storageConfigToEdit.git.repo = repo
      } else {
        console.error('Git repo not found')
        return
      }
    }

    const modal: ModalSettings = {
      type: 'component',
      component: 'storageFormModal',
      title: 'Edit storage config',
      body: 'Please update the storage config details.',
      buttonTextSubmit: 'Save',
      meta: { storageConfigId },
      value: storageConfigToEdit,
      response: async (response) => {
        if (!response) {
          return
        }
        const { payload, closeModal } = response
        await editStorageConfig(payload, storageConfigId, toastStore)
        closeModal()
      },
    }
    modalStore.trigger(modal)
  }

const prepareInputValuesForQuery = (
  inputValues: Record<string, unknown>,
  selectedStorageType: StorageType,
): Partial<IStorageConfig> => {
  const payload: Partial<IStorageConfig> = {}

  if (isS3(inputValues, selectedStorageType)) {
    const {
      accessKeyId = '',
      secretAccessKey = '',
      regionName = '',
      bucketUrl = '',
      endpointUrl = null,
    } = inputValues

    const editedEndpointUrl = endpointUrl === '' ? null : endpointUrl

    payload.s3 = {
      accessKeyId,
      secretAccessKey,
      regionName,
      bucketUrl,
      endpointUrl: editedEndpointUrl as string,
    }
  } else if (isGCS(inputValues, selectedStorageType) && selectedStorageType === StorageType.GCP) {
    const { bucketName, project, credentialsJson } = inputValues

    payload.gcp = {
      bucketName,
      project,
      credentialsJson,
    }
  } else if (isGit(inputValues, selectedStorageType) && selectedStorageType === StorageType.Git) {
    payload.git = {
      repo: {
        repositoryUrl: inputValues['repo.repositoryUrl'] as string,
        ...(inputValues['repo.plainAuth.username']
          ? {
              plainAuth: {
                username: inputValues['repo.plainAuth.username'] as string,
                password: inputValues['repo.plainAuth.password'] as string,
              },
            }
          : {}),
        ...(inputValues['repo.sshAuth.sshKey']
          ? {
              sshAuth: {
                sshKey: inputValues['repo.sshAuth.sshKey'] as string,
                sshPassword: inputValues['repo.sshAuth.sshPassword'] as string,
              },
            }
          : {}),
      },
      branch: inputValues['branch'],
    }
  }

  return payload
}

export type { IGitRepo, IStorageConfig, IStorageGCS, IStorageGit, IStorageS3 }
export {
  createStorageConfig,
  deleteStorageConfig,
  displayStorageTypes,
  getPattern,
  getStoragePrefix,
  isGCS,
  isGCSField,
  isGit,
  isGitField,
  isS3,
  isS3Field,
  loadStorageConfig,
  openCreateStorageConfigModal,
  openEditStorageConfigModal,
  parseConfigs,
  prepareInputValuesForQuery,
  storageConfigAsyncState,
  storageConfigStore,
  storageFields,
  StorageType,
  storageTypesValues,
}
