import { goto } from '$app/navigation'
import { type ModalSettings, type ModalStore, type ToastStore } from '@skeletonlabs/skeleton'
import camelcaseKeys from 'camelcase-keys'
import { writable } from 'svelte/store'

import type {
  IClassificationRunArgs,
  IODRunArgs,
} from '../dataset-optimization-runs/dataset-optimization-runs'
import type { IStorageConfig } from '@src/routes/storage-configs/storage-configs-store'
import { FetchError, handleFetch } from '@utils/fetch'

enum LabelingType {
  SingleLabelClassification = 'SingleLabelClassification',
  ObjectDetection = 'ObjectDetection',
  SpeechToText = 'SpeechToText',
  // TODO: add more types after backend update
}

const labelingTypesValues = Object.values(LabelingType)
const displayLabelingTypes = {
  [LabelingType.SingleLabelClassification]: 'Single Label Classification',
  [LabelingType.ObjectDetection]: 'Object Detection',
  [LabelingType.SpeechToText]: 'Speech to Text',
}

enum DatasetMetadataType {
  HirundoCSV = 'HirundoCSV',
  COCO = 'COCO',
  YOLO = 'YOLO',
}

const datasetMetadataTypesValues = Object.values(DatasetMetadataType)
const displayMetadataTypes = {
  [DatasetMetadataType.HirundoCSV]: 'Hirundo CSV',
  [DatasetMetadataType.COCO]: 'COCO',
  [DatasetMetadataType.YOLO]: 'YOLO',
}

class HirundoCSV {
  public type: DatasetMetadataType.HirundoCSV = DatasetMetadataType.HirundoCSV
  public csvUrl: string = $state('')

  toJSON() {
    return {
      type: this.type,
      csvUrl: this.csvUrl,
    }
  }
}

class COCO {
  public type: DatasetMetadataType.COCO = DatasetMetadataType.COCO
  public jsonUrl: string = $state('')

  toJSON() {
    return {
      type: this.type,
      jsonUrl: this.jsonUrl,
    }
  }
}

class YOLO {
  public type: DatasetMetadataType.YOLO = DatasetMetadataType.YOLO
  public dataYamlUrl: string | null = $state(null)
  public labelsDirUrl: string = $state('')

  toJSON() {
    return {
      type: this.type,
      dataYamlUrl: this.dataYamlUrl,
      labelsDirUrl: this.labelsDirUrl,
    }
  }
}

const isHirundoCSV = (
  labelingInfo?: ILabelingInfo,
  metadataType?: DatasetMetadataType,
): labelingInfo is HirundoCSV => {
  return metadataType == DatasetMetadataType.HirundoCSV
}

const isCOCO = (
  labelingInfo?: ILabelingInfo,
  metadataType?: DatasetMetadataType,
): labelingInfo is COCO => {
  return metadataType == DatasetMetadataType.COCO
}

const isYOLO = (
  labelingInfo?: ILabelingInfo,
  metadataType?: DatasetMetadataType,
): labelingInfo is YOLO => {
  return metadataType == DatasetMetadataType.YOLO
}

type ILabelingInfo = HirundoCSV | COCO | YOLO

// TODO: refactor fields after backend update
interface IOptimizationDataset {
  id: number
  name: string
  labelingType: LabelingType
  language?: string
  organizationId?: number
  storageConfig: IStorageConfig
  classes: string[] | null
  dataRootUrl: string
  labelingInfo: ILabelingInfo
  createdAt: Date
  updatedAt: Date
  creatorId: number
  creatorName?: string
  datasetMetadataType: DatasetMetadataType
}

type IRawOptimizationDataset = Omit<IOptimizationDataset, 'createdAt' | 'updatedAt'> & {
  createdAt: string
  updatedAt: string
}

const optimizationDatasetStore = writable(new Map<number, IOptimizationDataset>())

optimizationDatasetStore.subscribe((datasets) => {
  console.log('dataset updated: ', datasets)
})

const parseOptimizationDatasets = (rawDatasets: IRawOptimizationDataset): IOptimizationDataset => {
  const {
    id,
    name,
    labelingType,
    language,
    organizationId,
    classes,
    dataRootUrl,
    labelingInfo,
    createdAt,
    updatedAt,
    creatorId,
    creatorName,
    storageConfig,
    datasetMetadataType,
  } = rawDatasets

  return {
    id,
    name,
    labelingType,
    language,
    organizationId,
    classes,
    dataRootUrl,
    labelingInfo,
    createdAt: new Date(Date.parse(createdAt)),
    updatedAt: new Date(Date.parse(updatedAt)),
    creatorId,
    creatorName,
    storageConfig,
    datasetMetadataType,
  }
}

const optimizationDatasetAsyncState = writable({
  loading: false,
  error: '',
})

const loadOptimizationDatasets = async () => {
  optimizationDatasetAsyncState.set({
    loading: true,
    error: '',
  })
  try {
    const optimizations: IRawOptimizationDataset[] = camelcaseKeys(
      await (await handleFetch('/dataset-optimization/dataset/')).json(),
      { deep: true },
    )
    const optimizationMap = optimizations.reduce((acc, optimization) => {
      acc.set(optimization.id, parseOptimizationDatasets(optimization))
      return acc
    }, new Map<number, IOptimizationDataset>())
    optimizationDatasetStore.set(optimizationMap)
    optimizationDatasetAsyncState.set({
      loading: false,
      error: '',
    })
  } catch (err) {
    console.error('Failed to load optimizations: ', err)
    optimizationDatasetAsyncState.set({
      loading: false,
      error: 'Failed to load optimizations',
    })
  }
}

const runOptimization = async (
  dataset: { labelingType: LabelingType },
  datasetId: number,
  toastStore: ToastStore,
  modalStore: ModalStore,
  optimizationName: string | undefined,
) => {
  const modal: ModalSettings = {
    type: 'component',
    component: 'runDatasetOptimizationModal',
    // Data
    title: 'Run dataset optimization',
    body: 'You can customize run parameters in the advanced section below if you need or just click "Run now" to start the optimization run.',
    buttonTextConfirm: 'Run now',
    value: dataset,

    response: async (r: IClassificationRunArgs | IODRunArgs | boolean) => {
      if (!r) {
        return
      }
      try {
        await Promise.all([
          handleFetch(`/dataset-optimization/run/${datasetId}`, {
            method: 'POST',
            body: r && r != true ? { runArgs: r } : undefined,
          }),
          new Promise((resolve) => setTimeout(resolve, 1_000)),
        ])
        toastStore.trigger({
          message: `Started optimization run for dataset named <strong>${optimizationName}<strong>`,
          background: 'variant-filled-success',
        })
        goto(`/dataset-optimization-runs`)
      } catch (err) {
        console.error(err)
        if (err instanceof FetchError && err.message) {
          toastStore.trigger({
            message: `Failed to launch optimization run for dataset with error: ${err.message}`,
            background: 'variant-filled-error',
            autohide: false,
          })
          throw err
        }
        toastStore.trigger({
          message: 'Failed to launch optimization run for dataset',
          background: 'variant-filled-error',
        })
        throw err
      }
    },
  }
  modalStore.trigger(modal)
}

export type { IOptimizationDataset }
export {
  COCO,
  DatasetMetadataType,
  datasetMetadataTypesValues,
  displayLabelingTypes,
  displayMetadataTypes,
  HirundoCSV,
  isCOCO,
  isHirundoCSV,
  isYOLO,
  LabelingType,
  labelingTypesValues,
  loadOptimizationDatasets,
  optimizationDatasetAsyncState,
  optimizationDatasetStore,
  parseOptimizationDatasets,
  runOptimization,
  YOLO,
}
